import { Button, Form, Table } from 'react-bootstrap';
import { CREATE_TABLE_HEADER } from '../constants/tableHeader';
import { MenuCompositionStructureSetting } from 'features/CalorieManagement/MenuStructureSetting/models';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { MenuStructureSettingForm } from '../../MenuStructureSettingEdit';
import { useTranslation } from 'react-i18next';
import { DEFAULT_EFFECTIVE_END_DATE } from 'features/CalorieManagement/MenuStructureSetting/constants/defaultDate';
import DatePickerCustom from 'features/CalorieManagement/PartsConfigurationSetting/components/DatePickerCustom';

const CreateTable = ({
  compositions,
  disabled,
  onOpenAddCompositionModal,
  register,
  errors,
  onRemoveComposition,
  elementUnitMasters,
  purchaseUnitMasters,
  setValue,
  watch,
}: {
  compositions: MenuCompositionStructureSetting[];
  disabled: boolean;
  register: UseFormRegister<MenuStructureSettingForm>;
  errors: FieldErrors<MenuStructureSettingForm>;
  onOpenAddCompositionModal: (code: string) => void;
  onRemoveComposition: (idx: number) => void;
  elementUnitMasters: any[];
  purchaseUnitMasters: any[];
  setValue: any;
  watch: any;
}) => {
  const { t } = useTranslation();
  const CodeCell = ({ code }: { code: string }) => {
    return (
      <div
        style={{ color: '#3d8bfd', textDecoration: 'underline', cursor: 'pointer' }}
        onClick={() => onOpenAddCompositionModal(code)}
      >
        {code}
      </div>
    );
  };

  return (
    <div className="sticky-table">
      <Table>
        <thead>
          <tr>
            {CREATE_TABLE_HEADER.map((header, idx) => (
              <th style={{ width: header.width }} key={idx}>
                {t(`MenuStructureSettingScreen.${header.title}`)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {compositions.map((composition: any, idx) => {
            const effective_start_date = watch(`compositions.${idx}.effective_start_date`);
            const effective_end_date = watch(`compositions.${idx}.effective_end_date`);
            const isElement = composition.supplier_item_cd ? false : true;
            const filteredPurchaseUnitMasters = purchaseUnitMasters.filter(
              (unit) =>
                unit.purchase_unit_cd === composition.purchase_item_unit_cd ||
                unit.purchase_unit_cd === composition.process_item_unit_cd ||
                unit.purchase_unit_cd === composition?.supplementary_unit_cd_1 ||
                unit.purchase_unit_cd === composition?.supplementary_unit_cd_2 ||
                unit.purchase_unit_cd === composition?.supplementary_unit_cd_3
            );
            const filteredElementUnitMasters = elementUnitMasters.filter(
              (unit) =>
                unit.element_unit_cd === composition.element_unit_master ||
                unit.element_unit_cd === composition?.supplementary_unit_cd_1 ||
                unit.element_unit_cd === composition?.supplementary_unit_cd_2 ||
                unit.element_unit_cd === composition?.supplementary_unit_1 ||
                unit.element_unit_cd === composition?.supplementary_unit_2
            );
            return (
              <tr key={idx}>
                <td>
                  <CodeCell code={composition.code} />
                </td>
                <td>{composition.name}</td>
                <td className="text-center">
                  <div className="d-flex justify-content-between ">
                    <div>
                      <div className="d-flex justify-content-between align-middle">
                        <Form.Control
                          step={'0.1'}
                          style={{ width: 95 }}
                          isInvalid={Boolean(errors?.compositions?.[idx]?.usage_amount)}
                          {...register(`compositions.${idx}.usage_amount`)}
                          type="number"
                          min="0"
                          disabled={disabled}
                          onKeyDown={(evt) =>
                            (evt.key === 'e' || evt.key === '-' || evt.key === 'E' || evt.key === '+') &&
                            evt.preventDefault()
                          }
                        />
                      </div>
                      <div className="text-start">
                        {errors?.compositions?.[idx]?.usage_amount && (
                          <span className="small text-danger d-inline-block mt-1">
                            {errors?.compositions?.[idx]?.usage_amount?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div style={{ marginLeft: '5px' }}>
                      <div className="d-flex justify-content-between align-middle">
                        <Form.Select
                          isInvalid={Boolean(errors?.compositions?.[idx]?.unit)}
                          style={{ width: 100 }}
                          {...register(`compositions.${idx}.unit`)}
                          disabled={disabled}
                        >
                          {(isElement ? filteredElementUnitMasters : filteredPurchaseUnitMasters).map(
                            (option: any, idx: number) => {
                              return (
                                <option key={idx} value={isElement ? option.element_unit_cd : option.purchase_unit_cd}>
                                  {option.name}
                                </option>
                              );
                            }
                          )}
                        </Form.Select>
                      </div>
                      <div className="text-start">
                        {errors?.compositions?.[idx]?.unit && (
                          <span className="small text-danger d-inline-block mt-1">
                            {errors?.compositions?.[idx]?.unit?.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text-end ">
                  <div className="d-flex justify-content-between align-middle">
                    <Form.Control
                      key={composition.id}
                      style={{ width: 80 }}
                      isInvalid={Boolean(errors?.compositions?.[idx]?.sort_key)}
                      {...register(`compositions.${idx}.sort_key`)}
                      type="number"
                      min="0"
                      disabled={disabled}
                      onKeyDown={(evt) =>
                        (evt.key === 'e' || evt.key === '-' || evt.key === 'E' || evt.key === '+') &&
                        evt.preventDefault()
                      }
                    />
                  </div>
                  <div className="text-start">
                    {errors?.compositions?.[idx]?.sort_key && (
                      <span className="small text-danger d-inline-block mt-1">
                        {errors?.compositions?.[idx]?.sort_key?.message}
                      </span>
                    )}
                  </div>
                </td>
                <td className="text-end ">
                  <div className="d-flex justify-content-between align-middle">
                    <Form.Control
                      key={composition.id}
                      style={{ width: 70 }}
                      isInvalid={Boolean(errors?.compositions?.[idx]?.display_group)}
                      {...register(`compositions.${idx}.display_group`)}
                      type="number"
                      min="0"
                      disabled={disabled}
                      onKeyDown={(evt) =>
                        (evt.key === 'e' || evt.key === '-' || evt.key === 'E' || evt.key === '+') &&
                        evt.preventDefault()
                      }
                    />
                  </div>
                  <div className="text-start">
                    {errors?.compositions?.[idx]?.display_group && (
                      <span className="small text-danger d-inline-block mt-1">
                        {errors?.compositions?.[idx]?.display_group?.message}
                      </span>
                    )}
                  </div>
                </td>
                <td className="text-end ">
                  <DatePickerCustom
                    value={effective_start_date ? new Date(effective_start_date) : new Date()}
                    onChange={(date: Date) => {
                      setValue(`compositions.${idx}.effective_start_date`, date);
                    }}
                    disabled={disabled}
                  />
                </td>
                <td className="text-end ">
                  <DatePickerCustom
                    value={effective_end_date ? new Date(effective_end_date) : DEFAULT_EFFECTIVE_END_DATE}
                    onChange={(date: Date) => {
                      setValue(`compositions.${idx}.effective_end_date`, date);
                    }}
                    disabled={disabled}
                  />
                </td>
                <td className="text-end">
                  <Form.Control
                    key={composition.id}
                    isInvalid={Boolean(errors?.compositions?.[idx]?.remarks)}
                    {...register(`compositions.${idx}.remarks`)}
                    type="text"
                    disabled={disabled}
                  />
                </td>
                <td className="text-center">
                  <Button onClick={() => onRemoveComposition(idx)} disabled={disabled}>
                    {t('MenuStructureSettingScreen.delete')}
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export { CreateTable };
