import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { PDF_FONT_SIZE } from '../../constants/style';
import { default as dayjs } from 'dayjs';

const DateFrame = ({ title, date }: { title: string; date: string }) => {
  const styles = StyleSheet.create({
    dateContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'flex-end',
      fontSize: PDF_FONT_SIZE + 1,
      marginBottom: 6,
      paddingRight: 6,
      paddingLeft: 6,
      fontWeight: 'bold',
    },
  });
  return (
    <View fixed style={styles.dateContainer}>
      <Text>{`${title} ${dayjs(date).format('YYYY/MM/DD')} 改訂`}</Text>
    </View>
  );
};

export { DateFrame };
